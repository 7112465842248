<template>
  <div>
    <h1>Updates</h1>

    <ul>
      <li v-for="item in updates" v-bind:key="item.id">
        {{ item.data().title }} / {{ item.id }}
      </li>
    </ul>
  </div>
</template>
<script>
import { db } from "@/firebase";

export default {
  data() {
    return {
      updates: [],
    };
  },
  mounted() {
    db.collection("updates")
      .orderBy("timestamp", "desc")
      .limit(5)
      .onSnapshot((snapshot) => {
        this.updates = snapshot.docs;
      });
  },
};
</script>
