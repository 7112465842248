import VueRouter from "vue-router";

import Amenities from "./components/Amenities";
import Schedule from "./components/Schedule";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Updates from "./components/Updates";
import Page from "./components/Page";
import Test from "./components/Test";
import { auth } from "./firebase";

const routes = [
  { path: "/login", name: "login", component: Login },
  { path: "/logout", name: "logout" },
  { path: "/profile", name: "profile", component: Profile },
  { path: "/updates", name: "updates", component: Updates },
  { path: "/schedule", name: "schedule", component: Schedule },
  { path: "/page/:id", name: "page", component: Page },
  { path: "/", name: "home", component: Amenities },
  { path: "/test", name: "test", component: Test},
];

//   this.$router.go("/");

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes, // short for `routes: routes`
});

router.beforeEach(async (to, from, next) => {
  if (to.name != "login" && !auth.currentUser) {
    next("/login");
  } else if (to.name == "logout") {
    await auth.signOut();
    next("/");
  } else {
    next();
  }
});

export default router;
