<template>
  <div class="schedule">
    <div class="dark">
      <div class="container">
        <div class="row">
          <div class="column"
               v-for="day in schedule.slice(0, 2)"
               v-bind:key="'2.' + day.date.toString()">
            <h3>{{ displayDate(day.date) }}</h3>

            <table class="bigger">
              <tbody>
                <tr v-if="day.presence.length">
                  <td>
                    <img class="icon"
                         src="@/assets/emoji/64/1f3e2.png" />
                  </td>
                  <td>
                    <b>{{
                      day.presence.length + day.amenities.numberOfGuests
                    }}</b>
                    {{ $pluralize("people") }} in the office
                  </td>
                </tr>

                <tr v-if="day.amenities.numberOfGuests">
                  <td>
                    <img class="icon"
                         src="@/assets/emoji/64/1f465.png" />
                  </td>
                  <td>
                    <b>{{ day.amenities.numberOfGuests }}</b> of them are guests
                  </td>
                </tr>
                <tr v-if="day.amenities.lunch.length">
                  <td>
                    <img class="icon"
                         src="@/assets/emoji/64/1f96a.png" />
                  </td>
                  <td>
                    <b>{{ day.amenities.lunch.length }}</b> will join for lunch
                  </td>
                </tr>
                <tr v-if="day.amenities.dinner.length">
                  <td>
                    <img class="icon"
                         src="@/assets/emoji/64/1f37d-fe0f.png" />
                  </td>
                  <td>
                    <b>{{ day.amenities.dinner.length }}</b> will join for
                    dinner
                  </td>
                </tr>
                <tr v-if="day.amenities.parkingSpot.length">
                  <td>
                    <img class="icon"
                         src="@/assets/emoji/64/1f698.png" />
                  </td>
                  <td>
                    <b>{{
                      parkingSpotCapacity - day.amenities.parkingSpot.length
                    }}</b>
                    parking
                    {{
                      $pluralize(
                        "spot",
                        parkingSpotCapacity - day.amenities.parkingSpot.length
                      )
                    }}
                    left
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <section id="details">
      <div class="container">
        <table class="more-options">
          <tbody>
            <template v-for="day in schedule">
              <tr v-bind:key="day.date.format('MMMM DD')">
                <td class="day">
                  <span class="bigger">{{ displayDate(day.date) }}</span><span class="date">{{ day.date.format("MMMM DD") }}</span>
                </td>
                <td>
                  <button class="value"
                          v-on:click="showDetails(day, 'presence')"
                          v-bind:class="{ active: day.showDetails == 'presence' }"
                          :disabled="!day.presence.length">
                    <img class="icon"
                         src="@/assets/emoji/64/1f3e2.png"
                         alt="🏢" />

                    <span>{{ day.presence.length }}</span>
                  </button>

                  <button class="value"
                          :disabled="day.amenities.guests == 0"
                          v-on:click="showDetails(day, 'guests')"
                          v-bind:class="{ active: day.showDetails == 'guests' }">
                    <img class="icon"
                         src="@/assets/emoji/64/1f465.png"
                         alt="🏢" />

                    <span> {{ day.amenities.numberOfGuests }}</span>
                  </button>

                  <button class="value"
                          :disabled="!day.amenities.lunch.length"
                          v-on:click="showDetails(day, 'lunch')"
                          v-bind:class="{ active: day.showDetails == 'lunch' }">
                    <img class="icon"
                         src="@/assets/emoji/64/1f96a.png"
                         alt="🏢" />

                    <span> {{ day.amenities.lunch.length }}</span>
                  </button>

                  <button class="value"
                          :disabled="!day.amenities.dinner.length"
                          v-on:click="showDetails(day, 'dinner')"
                          v-bind:class="{ active: day.showDetails == 'dinner' }">
                    <img class="icon"
                         src="@/assets/emoji/64/1f37d-fe0f.png"
                         alt="🏢" />

                    <span> {{ day.amenities.dinner.length }}</span>
                  </button>

                  <button class="value"
                          :disabled="!day.amenities.parkingSpot.length"
                          v-on:click="showDetails(day, 'parkingSpot')"
                          v-bind:class="{ active: day.showDetails == 'parkingSpot' }">
                    <img class="icon"
                         src="@/assets/emoji/64/1f698.png"
                         alt="🏢" />

                    <span> {{ day.amenities.parkingSpot.length }}</span>
                  </button>
                  <button class="value"
                          :disabled="!day.remarks.length"
                          v-on:click="showDetails(day, 'remarks')"
                          v-bind:class="{ active: day.showDetails == 'remarks' }">
                    <img class="icon"
                         src="@/assets/emoji/64/1f4dd.png" />

                    <span> {{ day.remarks.length }}</span>
                  </button>
                </td>
              </tr>
              <tr v-if="day.showDetails"
                  class="schedule-details"
                  v-bind:key="day.date.format('MMMM DD') + '-details'">
                <td colspan="2">
                  <div class="card">
                    <table>
                      <tr v-for="record in day.scheduleDetails"
                          v-bind:key="record.id">
                        <td style="display: flex">
                          {{ record.caption
                          }}<span v-for="icon in record.icons"
                                v-bind:key="icon.img"
                                style="margin-left: 4px; display: inline-flex"><img class="icon"
                                 v-if="icon.img"
                                 :src="'/assets/emoji/64/' + icon.img" /></span>
                        </td>

                        <td class="value">
                          {{ record.value }}
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
              </tr>
            </template>
          </tbody>
        </table>
      </div>
    </section>
  </div>
</template>
<script>

import { db } from "@/firebase";
import { collection,  query, where, onSnapshot } from 'firebase/firestore';


import { mapGetters } from "vuex";
import config from "@/config";
import _ from "lodash";

export default {
  data() {
    return {
      schedule: [],
      parkingSpotCapacity: config.parkingSpotCapacity,
    };
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  methods: {
    showDetails(record, detail) {
      if (detail == record.showDetails) {
        //toggle off
        record.showDetails = null;
        return;
      }
      record.showDetails = detail;

      if (detail == "presence") {
        record.scheduleDetails = record[detail].map((x) => {
          return {
            caption: x.user?.displayName || "John Doe",
            value: null,
          };
        });
      } else if (detail == "guests") {
        record.scheduleDetails = record.amenities.guests.map((x) => {
          return {
            value: x.amenities.guests,
            caption: x.user?.displayName || "John Doe",
          };
        });
      } else if (
        detail == "breakfast" ||
        detail == "lunch" ||
        detail == "dinner"
      ) {
        record.scheduleDetails = record.amenities[detail].map((x) => {
          
          return {
            caption: x.user?.displayName || "John Doe",
            icons: [
              { img: x.foodPreference == "vegan" ? "1f96c.png" : null },
              { img: x.foodPreference == "vegetarian" ? "1f957.png" : null },
              { img: x.allergies && x.allergies.dairy ? "1f9c0.png" : null },
              { img: x.allergies && x.allergies.wheat ? "1f33e.png" : null },
            ],
            value: null,
          };
        });
      } else if (detail == "parkingSpot") {
        record.scheduleDetails = record.amenities[detail].map((x) => {
          return {
            caption: x.user?.displayName || "John Doe",
            value: null,
          };
        });
      } else if (detail == "remarks") {
        record.scheduleDetails = record.remarks.map((x) => {
          return {
            caption: x.user?.displayName || "John Doe",
            value: x.remarks,
          };
        });
      } else {
        record.scheduleDetails = record[detail];
      }
    },
    displayDate(date) {
      if (date.isToday()) return "Today";
      //if (date.isTomorrow()) return "Tomorrow";
      return date.format("dddd");
    },
  },
  async mounted() {
    this.schedule = [];

    const cutoff = this.$date().add(config.scheduleDays, "day");
    let currentDate = this.$date();
    let offset = 0;

    while (offset <= config.scheduleDays) {
      let d = currentDate.add(offset, "day");

      offset++;
      if (d.day() == 0 || d.day() == 6) continue;

      this.schedule.push({
        date: d,
        presence: [],
        remarks: [],

        showDetails: false,
        foodPreference: [],
        amenities: {
          breakfast: [],
          lunch: [],
          dinner: [],
          guests: [],
          vegan: [],
          vegetarian: [],
          parkingSpot: [],
          numberOfGuests: 0,
        },
      });
    }


    const q = query(collection(db, "registrations"),
      where("date", "<=", parseInt(cutoff.format("YYYYMMDD"))),
      where("date", ">=", parseInt(this.$date().format("YYYYMMDD")))
    );


    onSnapshot(q, (scheduleSnapshot) => {


      const grouped = _(scheduleSnapshot.docs.map((x) => x.data()))
        .groupBy((x) => x.date)
        .map((objs, key) => ({
          date: key,
          presence: _.filter(objs, (x) => x.presence),
          remarks: _.filter(objs, (x) => x.remarks),
          foodPreference: _.filter(objs, (x) => x.foodPreference),
          amenities: {
            breakfast: _.filter(objs, (x) => x.amenities.breakfast),
            lunch: _.filter(objs, (x) => x.amenities.lunch),
            dinner: _.filter(objs, (x) => x.amenities.dinner),
            guests: _.filter(objs, (x) => x.amenities.guests > 0),
            numberOfGuests: _.sumBy(objs, (x) => x.amenities.guests || 0),
            parkingSpot: _.filter(objs, (x) => x.amenities.parkingSpot),
          },
        }))
        .value();
        
      grouped.forEach((doc) => {
        const record = this.schedule.find(
          (x) => parseInt(x.date.format("YYYYMMDD")) == doc.date
        );
        if (!record) return;

        record.presence = doc.presence;
        record.remarks = doc.remarks;
        record.foodPreference = doc.foodPreference;
        record.amenities.breakfast = doc.amenities.breakfast;
        record.amenities.lunch = doc.amenities.lunch;
        record.amenities.dinner = doc.amenities.dinner;
        record.amenities.guests = doc.amenities.guests;
        record.amenities.numberOfGuests = doc.amenities.numberOfGuests;
        record.amenities.parkingSpot = doc.amenities.parkingSpot;
      });
    });




  },
};
</script>