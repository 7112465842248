import Vue from 'vue';
import dayjs from 'dayjs';
dayjs.extend(require("dayjs/plugin/isTomorrow"));
dayjs.extend(require("dayjs/plugin/isToday"));

Object.defineProperties(Vue.prototype, {
    $date: {
        get() {
            return dayjs
        }
    }
});